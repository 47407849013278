<template>
  <div>
    <component-menu></component-menu>
    <div class="container">
      <div class="row">
        <div class="col-lg-12 mb-2">
          <h2>{{$t("lang.application.uuid_generator.name")}}</h2>
          <p class="fw-light">{{$t("lang.application.uuid_generator.detailed_description")}}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 mb-2">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <label for="process-number" class="col-lg-12 form-label text-start">{{$t("lang.label.number")}}</label>
                <div class="col-lg-12">
                  <input type="number" class="form-control mb-2" id="process-number" min="1" v-model="createForm.number">
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12 text-end">
                  <button type="button" class="btn btn-primary btn-sm mb-2" data-dismiss="modal" @click="executeProcess($event)">{{$t("lang.label.generate")}}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 mb-2" id="process-result">
          <div class="card" v-if="processResults.loaded === true">
            <div class="card-body">
              <div class="row">
                <label for="process-result-text" class="col-lg-12 form-label text-start">{{$t("lang.label.output")}}</label>
                <div class="col-lg-12">
                  <textarea class="form-control mb-2" id="process-result-text" rows="3" v-model="resultForm.target" readonly></textarea>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12 text-end">
                  <button type="button" class="btn btn-info btn-sm mb-2" @click="executeProcessAfter($event, 'copy')">{{$t("lang.label.copy")}}</button>
                </div>
              </div>
            </div>
          </div>
          <div class="card" v-else>
            <div class="card-body">
              <div class="alert alert-info" role="alert">{{$t("lang.alert.result")}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <component-footer></component-footer>
  </div>
</template>

<script>
  import Vue from 'vue';
  import $ from 'jquery';
  export default {
    metaInfo : {
      title: `${window.application.title} ${window.application.lang.application.uuid_generator.name}`,
      meta: [
        {name: 'description', conent: `${window.application.lang.application.uuid_generator.meta_description}`}
      ]
    },
    head: {
      link: [
        {rel: 'alternate', hreflang: 'en', href: 'https://ezeful.app/en/uuidgenerator'},
        {rel: 'alternate', hreflang: 'es', href: 'https://ezeful.app/es/uuidgenerator'},
        {rel: 'alternate', hreflang: 'hi', href: 'https://ezeful.app/hi/uuidgenerator'},
        {rel: 'alternate', hreflang: 'ja', href: 'https://ezeful.app/ja/uuidgenerator'},
        {rel: 'alternate', hreflang: 'zh-cn', href: 'https://ezeful.app/zh_cn/uuidgenerator'},
        {rel: 'alternate', hreflang: 'zh-tw', href: 'https://ezeful.app/zh_tw/uuidgenerator'},
      ],
      //script: [
      //  { src: window.application.adsense_script, async: true, crossorigin:"anonymous"},
      //],
    },
    data () {
      return {
        lang: window.application.lang,
        createForm: {
          number: 1
        },
        resultForm: {
          target: ""
        },
        processResults: {
          loaded: false,
          length: 0
        }
      }
    },
    created() {
      Vue.component('component-menu', require('../components/Menu.vue').default);
      Vue.component('component-footer', require('../components/Footer.vue').default);
    },
    mounted() {
      //let self = this
    },
    updated() {
      //let self = this
    },
    methods: {
      executeProcess(event) {
        let self = this;
        let obj = window.button.loading(event);
        window.display.loading(self.processResults);

        let uuidText = "";
        self.resultForm.target = "";

        try {
          for (let i = 0; i < parseInt(self.createForm.number, 10); i++) {
            uuidText += self.generateUuid();
            if (i != (parseInt(self.createForm.number, 10) - 1)) {
              uuidText += "\n";
            }
          }

          self.resultForm.target = uuidText;
        } catch(e) {
          self.resultForm.target = self.lang.validation.unknown;
        }
        window.display.reset(self.processResults);
        window.button.reset(obj);
        window.location.element('#process-result');
      },
      executeProcessAfter(event, process) {
        let self = this;
        let obj = window.button.loading(event);
        window.display.loading(self.processResults);

        try {
          if (process == 'copy') {
            self.copy();
          }
        } catch(e) {
          self.resultForm.target = self.lang.validation.unknown;
        }

        window.display.reset(self.processResults);
        window.button.reset(obj);
        //window.location.element('#process-result');
      },
      copy() {
        let self = this;
        let textarea = $("#process-result-text");
        textarea.select();
        document.execCommand("copy");
        window.getSelection().removeAllRanges();
        alert(self.lang.alert.copied);
      },
      generateUuid() {
        let chars = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".split("");
        for (let i = 0, len = chars.length; i < len; i++) {
          switch (chars[i]) {
            case "x":
              chars[i] = Math.floor(Math.random() * 16).toString(16);
              break;
            case "y":
              chars[i] = (Math.floor(Math.random() * 4) + 8).toString(16);
              break;
          }
        }
        return chars.join("");
      }
    }
  }
</script>

<style>

  /** Extra Small Screen (col-xs) ********************************************************/
  @media (max-width: 767px) {

  }

  /** Small Screen (col-sm) **************************************************************/
  @media (min-width: 768px) {

  }

  /** Medium Screen (col-md) *************************************************************/
  @media (min-width: 992px) {

  }
  /** Large Screen (col-lg) **************************************************************/
  @media (min-width: 1200px) { /*元は1200px*/

  }

</style>
